var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',[_c('template',{slot:"title"},[_vm._v(" Products ")]),_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"loading":_vm.inProcess,"hide-default-footer":""},on:{"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"max-width":"48","max-height":"48","src":item.icon.originalUrl}})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-10"},[_c('strong',[_vm._v(_vm._s(item.title))])])]}},{key:"item.externalIds",fn:function(ref){
var item = ref.item;
return _vm._l((item.externalIds),function(externalVal,externalId){return _c('div',{key:("external-id-" + externalId)},[_vm._v(" "+_vm._s(externalId)+": "+_vm._s(externalVal)+" ")])})}},{key:"item.prices",fn:function(ref){
var item = ref.item;
return _vm._l((item.prices),function(price,currency){return _c('div',{key:("price-" + currency),staticClass:"mt-2"},[_vm._v(" "+_vm._s(currency)+" "+_vm._s(price.amount)+" ")])})}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }