<template>
  <card>
    <template slot="title">
      Products
    </template>
    <v-data-table
      :items="items"
      :headers="headers"
      :loading="inProcess"
      @click:row="onClickRow"
      hide-default-footer
    >
      <template
        v-slot:item.icon="{ item }"
      >
        <v-img
          max-width="48"
          max-height="48"
          :src="item.icon.originalUrl"
        />
      </template>
      <template
        v-slot:item.title="{ item }"
      >
        <div
          class="my-10"
        >
          <strong>{{ item.title }}</strong>
        </div>
      </template>
      <template
        v-slot:item.externalIds="{ item }"
      >
        <div
          v-for="(externalVal, externalId)  in item.externalIds"
          :key="`external-id-${externalId}`"
        >
          {{ externalId }}:&nbsp;{{ externalVal }}
        </div>
      </template>
      <template
        v-slot:item.prices="{ item }"
      >
        <div
          v-for="(price, currency) in item.prices"
          :key="`price-${currency}`"
          class="mt-2"
        >
          {{ currency }} {{ price.amount }}
        </div>
      </template>
    </v-data-table>
  </card>
</template>
<script>
import Card from '@/components/card'
import { getStorageDownloadURL } from '@/fb'

export default {
  name: 'DashboardProducts',
  components: {
    Card
  },
  data: () => ({
    inProcess: true,
    excludeKeys: [],
    originalData: [],
    items: [],
    headers: []
  }),
  async created() {
    this.inProcess = true

    const response = await this.$store.dispatch('getProducts') || []

    this.originalData = response

    const keys = ['icon', 'title', 'description', 'externalIds', 'prices', 'price', 'currency', 'enabled']
    const headers = keys.map(key => ({ value: key, text: key }))

    const items = response.map(item => {
      const { info } = item[1]
      return keys.reduce((acc, key) => {
        acc = {
          ...acc,
          [key]: info[key] || item[1][key]
        }
        return acc
      }, {})
    })


    const icons = await Promise.all(items.map(item => getStorageDownloadURL(item.icon.origin)))

    this.items = items.map((item, index) => {
      item.icon.originalUrl = icons[index]
      return item
    })
    this.headers = headers

    this.inProcess = false
  },
  methods: {
    onClickRow(row, data) {
      row
      const { index } = data
      const [ id ] = this.originalData[index]
      if (!id) return
      this.$router.push({ path: `/dashboard/products/${id}`})
    }
  }
}
</script>